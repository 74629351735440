.horizontalCell {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  overflow: hidden;
}


.horizontalRow {
  display: flex;
  box-sizing: border-box;
  flex-wrap: nowrap;
  flex-shrink: 0;
  transition: transform .3s ease;

  & > * {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
};


.horizontalRowDragging {
  transition: iniitial;
}
.storiesWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 11px 12px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
}

.storyIndicator {
  height: 4px;
  flex: 1;
  margin: 0 4px;
  background-color: rgba(255,255,255,0.4);
  display: flex;
}

.storyIndicatorActive {
  border-radius: 1px;
  background: white;
}

.currentStoryIndicator {
  background-color: white;
  align-self: stretch;
}
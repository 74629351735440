.wrapper {
  box-sizing: border-box;
  position: relative;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.playPauseButton {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.playButtonWrapper {
  background-color: rgba(0,0,0,0.3);
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomInfo {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 15px 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0));
}

.infoElement {
  z-index: 3;
}

.getInButton {
  width: 80px;
  height: 40px;
  background: #396BCC;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 15px;
}

.avatarContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #2c2c2c;

  & > img {
    width: 100%;
  }

  & + .avatarContainer {
    margin-left: -5px;

  }
}

.avatarRow {
  display: flex;
  margin: 0;
  left: 0;
  position: initial;
  margin-bottom: 16px;
}

.avatarWrapper {
  position: relative;
  & + .avatarWrapper {
    margin-left: -5px;
  }
}

.score {
  background: black;
  color: white;
  font-size: 13px;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  position: absolute;
  border-radius: 4px;
  padding: 0px 4px;
  height: 16px;
  line-height: 13px;
  display: flex;
  align-items: center;
}

.infoBlock {
  
}

.playerWrapper {
  position: relative;
  align-self: stretch;
}

.videoCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.cdnfonts.com/css/pt-root-ui');

::-webkit-scrollbar {
    width: 0;
  }

html {
    // background: #dddddd;
}

p {
    font-size: 14px;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'PT Root UI', sans-serif;
    // background-color: #396BCC;
}  

.background {
   filter: blur(18px);
    -webkit-filter: blur(18px); 
    opacity: 0.3;
}

video {
    object-fit: cover;
    border-radius: 40px;
}

.header {
    display: flex;
    justify-content: left;
    height: 80px;
}

.footer {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.main {
    // min-width: 375px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

// left-desktop-container
.leftContainer {
    height: 100%;
    // border: 1px solid green;
    width: 100%;
    width: 568px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 158px;
}

.leftBlock { 
    flex-direction: column;
    height: 500px;
    // border: 1px solid red;
    align-items: center;
}

.topHomeContainer {
    display: flex;
    flex-direction: column;
}

.bottomHomeContainer {
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    width: 366px;
    height: 50%;
}

.logoContainer {
    margin-bottom: 25px;
}

.logo {
    width: 395px;
    height: 93px;
}

.storeContainerMobile {
    position: absolute;
    z-index: 199;
    display: flex;
    flex-direction: column;
    top: 30px;
    left: 24px;
}

.logoMobile {
    width: 140px;
    height: auto;
}
.homeLinkBlock {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    line-height: 25px;
}

.bottom-link {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 12px;
    text-decoration-line: underline;
    text-underline-offset: 4px;
}
.bottom-link:hover {
    text-decoration: none;
    color: #ddd;
}
.itemLeftBlock {
    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: flex-start;
}

.storeContainer {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}

.appStoreLogo {
    width: auto;
    height: 56px;
}

.gpLogo {
    width: auto;
    height: 56px;
    margin-left: 8px;
}
// right-desktop-container
.rightContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 568px;
    // border: 1px solid red;
}

.rightBlock {
    width: 338px;
    height: 680px;
    border-radius: 40px;
    box-shadow: 96px 96px 96px rgba(0, 0, 0, 0.16), 64px 64px 64px rgba(0, 0, 0, 0.16), 32px 32px 32px rgba(0, 0, 0, 0.24), 16px 16px 16px rgba(0, 0, 0, 0.24), 8px 8px 8px rgba(0, 0, 0, 0.24);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    // bottom: 43px;
    left: 5px;
}

.phoneContainer {
    width: 347px;
    height: 701px;
    z-index: 200;
}

.videoContainer {
    width: 347px;
}

.bottomBlock {
    position: relative;
    top: -39px;
}

.avatarRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    bottom: 85px;
    left: 25px;
}

.infoBlock {
    position: absolute;
    bottom: -25px;
    left: 25px;
    // border: 1px solid yellow;
    z-index: 201;
}

.statusBar {
    width: 346px;
    height: 53px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVoAAAA1CAYAAAAAhCNNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAVMSURBVHgB7dt9iFRVHMbx32qKhSxRJlJWi4QtYWn0ohRRQWRvlhWSvS9IEIbUHxESxRIFkhUlFfaC0SZREEJSQWC1IUoumYlERflHsGstFimVIrrb9DycM3kddt2ddWWc8fuBhzt7z713cObe3z3nzDUCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABzUFABxlpVLpBC0mK+OV/cqfTU1N+4axn2vUuBiZfr1HfxwD6q7Q6oOfFukL26YPcW9ed4oWFylnKD8rXWrrG+I4/uIvzn9+r+3/KrSdp8UFirfZqrbvAsAhdJ2crsUq5R9lUfEaKmzTosVCZa7i7f8vtMqXSof2+3GQ45+pxbl+GSPj+uZr+9eosboptLkw3qU8HanQztYHuE3rp+j1GmW68ocyVXlPWexiq/Yx3l+v/604nr/8NyMV07lqX5/Xt2nxrLJT8T4+/jK1vxTAcS5fh2N0PezX65v1em1umunrsbDdBC2WKIuVlkjFuEdxL9bF1kX3ZKU3UrF+Xvvvrniv27T4Rmn1e0Z13NH6SblQx/0wMDz60B9VdigflZJZeX27ske5xEVXeUrpV+bk9uVKR8Wxpirdyup8rKsKbV7fqUxyT1lZo+zKJxhw3HLxVNYpG5VWZX7poFkV2y7N1+HvysvKbPduc8729vna/S3v/2LF/k3K7S62+TjVOqDMy8cYV0pTEDVTT8XDw41bIvVYbyqs/0zZorvW1/5DH+iGSHe/E3P7zEi90sjtbnsu0t31VeWeivdxL9bDmt2F14edhgAaVe6ZTtT15dGir6NrclPrELu6F3mF0h7pWmpTrvSxIl3LHkF65Lk2b9NRsX95XrY5qu/Nmmvbqcrf+T1nuDbo3zHSaYgjUk+F9hUP/90bLa7Uuo3l12rzUOSxSEOGrrz63jj033mdckOkOaOBJuMfVlYqH0Q6IdwzXjLUnC/QoN5VpuvaelLLrcPdyfOu2udWvbxU6YzUQfIcrjsuE/P6+5SHlAVH+fpyofaNYZNyIGpgJHeKmqicY62kL9Vf3tuKhzALyz+UabmzPBmet3lBeUvrNg1wDBdk/wjmAuw7n9/TJ8BlahsfQIPTeT7NU2nKWXnVfGVGpJFhVTyPG2kO1r93LFeuLmRppJ5u7yBFdjR7nj5Wt96nJkXWGmLeMRfQ15TLlTv1gX5baPNJMkHrNkc6aXxn89xsW6RJevM8Tnki3ndu94pX5B70Ir1+I9IQ5/MAGptHc9cq7yj3xxF2xnQNbdc1dH2kDsvjkZ4O2q4sU1YP9kSAi2KeV3Wh9rRFtbWqL+/nqQ/3pL+IGqr7QlsosvMiPZWwIc8r9eU7ped+fHc+LdKdzcOfBXn3iXnp6YSvlF2RPpO9hR70nkgn25QAGt/kvGyOUeJRZSk9HXRjpI6OOz8rtX7LELu6B/qD4imIamuV990R6WmImk/7NUKP9kHl7kh3rSdybIXyfqQifFJe58fAPins6x7wx8ojyrpIdz8/ovKMToxz8jZ+DMzP+XUG0PjcEfFvHZvz3/5x2fOr7oV61OffP1w3enK71/cV/h6Qil1vnrP1o1ztwyiy5h/Mzo/0OFi1UwljI41Y18cxoB7/w4LviHcor+cvzz9qzRlg00/V3jXEsVoiDY/80PQveZ17vw9Emuv1CeUTblW5HWhkeYQ4KdLc6T4/5hhp5NeTn0v3869j9bo7b+8i7NFj7zCP3zzQf2wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADA6PkPW9NovaRSO6QAAAAASUVORK5CYII=);
    z-index: 100;
    position: relative;
    bottom: 372px;
    left: -3px;
}
.specContainer {
    bottom: 35px;
    position: absolute;
    width: 350px;
}
// ico block
.icoBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 198;
    margin-right: 35px;
    margin-bottom: 85px;
}

.icoBlockMoble {
    position: absolute;
    z-index: 250;
    bottom: 150px;
    right: 15px;
}

.storiesContainer {
    position: absolute;
    height: 4px;
    width: 280px;
    bottom: 0px;
    left: 25px;
    display: flex;
    flex-direction: row;
}

.playButton {
    position: absolute;
    z-index: 400;
    height: 363px;
    width: 224px;
    background: none;
    // border-width: 1px;
    // border-color: red;
    z-index: 1000;
    border-radius: 200px;
    opacity: 0.6;
    bottom: 130px;
    left: 51px;
}
.itemButton {
    width: 80px;
    height: 40px;
    background: #396BCC;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 250;
    position: absolute;
    bottom: 15px;
}
.avatarContainer {
    width: 40px;
    height: 60px;
    position: absolute;
}

.avatarItem {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #2C2D2E;
    background-size: contain;
}

.baloonText {
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    color: #ffffff;
}

.avatarRate {
    width: 71px;
    height: 38px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    // border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 20px;
    background: #2C2D2E;
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rateRow {
    display: flex;
    flex-direction: row;
}
.title0 {
    font-size: 44px;
    line-height: 52px;
    font-weight: 700;
    color: #000;
}

.title2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #ffffff;
}

.title3 {
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    font-weight: bold;
}

.title4white {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #ffffff;
}

.title4gray {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #76787A;
    text-align: left;
}

.title1 {
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    color: #ffffff;
    text-align: left;
}

.gameType {
    margin: 0;
}

.gameName {
    margin: 0;
}

.hashTagLine {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.hashData {
    margin: 0;
}
.lastLine {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.userIco {
    height: 13px;
    width: 12px;
    margin-right: 5px;
}

.whisleIco {
    height: 13px;
    width: 13px;
    margin-left: 5px;
    margin-right: 5px;
}

.swiperContainer {
    position: absolute;
    width: 95px;
    height: 130px;
    margin-top: 181px;
    margin-left: 26px;
}

.swiperButton {
    width: 42px;
    height: 67px;
    border: 4px solid rgba(255, 255, 255, 0.88);
    box-sizing: border-box;
    border-radius: 24px;
    // position: absolute ;
    // left: 0px;
}

.swiperTouch {
    position: absolute;
    left: 8px;
    top: 35px;
    height: 25px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrowContainer {
     position: absolute;
    // top: 14px;
    left: 6px;
    // background-color: red;
    width: 30px;
    height: 30px;
    background: url(./img/arrow.png);
    background-size: 12px 14px;
    background-repeat: no-repeat;
    background-position: 9px 0px;
    // border: 1px solid red;
}

.arrow {
    height: 14px;
    width: 12px;
}

.swipeText {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #ffffff;
    text-align: left;
    position: absolute;
    top: 80px;
    left:0;
}
.icoItem {
    // margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 250;
}

.icoText {
    color: #fff;
	font-size: 13px;
	line-height: 16px;
    text-align: center;
    margin-top: 10px;	
}
.languageContainer {
    position: absolute;
    z-index: 500;
    // top: 80px;
    // left: 80px;
    top: 20px;
    right: 400px;
}

.cIcon {
    width: 16px;
    height: 11px;
    display: inline-block;
    margin-right: 5px;
    background: url(./img/flags.png) no-repeat;
    vertical-align: unset;
}

.cIcon.c-ru {
    background-position: -224px -121px;
}

.cIcon.c-es {
    background-position: -16px -44px;
}

.cIcon.c-it {
    background-position: -208px -66px;
}

.cIcon.c-de {
    background-position: -80px -33px;
}

.cIcon.c-gb {
    background-position: -176px -44px;
}

// for slider
ul {
    padding-inline-start: 0
}

ul li {
    display: flex;
    flex-direction: row;
}

.dropdown-menu {
    min-width: 0;
}

.dropdown-item {
    padding: 0.25rem 1.17rem;
}

.static-page {
    min-width: 375px;
    display: flex;
    justify-content: center;
    // border: 1px solid green;
    // height: 800px;
}

.static-container {
    width: 70vw;
    min-height: 80vh;
    // display: flex;
    // justify-content: center;
    background: #fff;
    padding: 20px;
}

.static-header {
    margin-top: 10px;
}

.static-header h1 {
    color: #fff;
}

.mobileText {
    font-size: 18px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.88);
    font-weight: bold;
    display: none;
}

.linkRowMobile {
    display: none;
    position: absolute;
    top: 750px;
}

.header-desktop {
    // position: absolute;
    // right: 40px;
    // top: 40px;
}

.header-mobile {
    margin-left: 22px;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-toggle {
    padding: 0;
    background: none !important;
}

.btn-secondary {
    border: none;
}

.dropdown-item {
    padding: 0;
}
.show {
    background: none;
    border: none;
}

.mobileMenu {
    display: none;
    padding: 0;
    background: rgba(0, 0, 0, 0.24);
    padding-bottom: 3px;
    position: absolute;
}

.menuContainer {
    position: absolute;
    top: 0;
    right: 0;
    // width: calc(100vw/2);
    width: 70vw;
    height: 100vh;
    background: #fff;
    z-index: 400;
    align-content: center;
}
.menuContainer ul{
    padding-left: 22px;
    padding-top: 20px;
}

.menuContainer ul li{
    padding-top: 10px;
}

.menuLink {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: #7A8599;
    margin-left: 10px;
}

.clickArea {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 240px);
    z-index: 400;
    // border: 1px solid red;
}

.playButtonMobile {
    position: absolute; 
    z-index: 400;
    height: 40px;
    width: 40px;
    background: none;
    z-index: 180;
    border-radius: 200px;
    // border: 1px solid red;
}

.btn:focus {
    outline:0 !important;
    box-shadow: none;
}

.closeIcon {
    height: 10px;
    width: 10px;
}

.closeContainer {
    z-index: 1010; 
    height: 24px; 
    width: 24px;
    background-color: rgba(0, 0, 0, 0.24);
    border-radius: 32px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
    justify-content: center;
}
.logoIcon {
    height: 40px;
    width: auto;
    // position: absolute;
    // top: 20;
    margin-top: 8vh;
}
// white page 
.static-white-page {
    min-width: 375px;
    display: flex;
    justify-content: center;
    height: 112vh;
    background-color: #fff;
    width: 100vw;
    position: absolute;
}
.static-white-sub_page_container {
    // border: 1px solid blue;
}

.animate-enter {
    opacity: 0;
    transition: opacity 500ms;
}
.animate-enter-active {
    opacity: .5;
    transition: opacity 250ms;
}
.animate-enter-done {
    opacity: 1;
    transition: opacity 500ms;
}
// .animate-exit {
//     opacity: 0;
// }
// .animate-exit-active {
//     opacity: .5;
//     transition: opacity 300ms;
// }
// .animate-exit-done {
//     opacity: 1;
// }

.static-white-page_header {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    height: 72px;
    // width: 90vw;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    width: 1136px;
    background: #fff;
}
.static-white-page_logo {
    height: 31px;
    width: 125px;
}
.static-white-page_container {
    // border: 1px solid red;
    display: flex;
    // width: 90vw;
    width: 1136px;
    //margin-top: 64px;
    height: 86vh;
}

.static-white-page_block {
    // border: 1px solid green;
    width: 568px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fixedRightBlock {
    position: fixed;
    top: calc(25vh - 35px);
    // right: 25vh;
    margin-left: 710px;
    // border: 1px solid green;
    width: 568px;
}

.static-white-page_footer {
    display: flex;
    flex-direction: row;
    height: 84px;
    width: 1136px;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 24px;
    padding-bottom: 24px;
    position: fixed;
    bottom: 0;
    background: #fff;
}

.static-white-page_logo_bottom {
    width: 106px;
    height: 36px;
}


.anchorMenu {
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    width: 1136px;
    background: #fff;
}

.anchorMenuItem {
    border: 1px solid #D7D8D9;
    border-radius: 24px;
    padding: 4px 16px 4px 16px;
    margin-right: 16px;
    text-decoration: none;
}
.anchorMenuItem:hover {
    text-decoration: none;
    cursor: pointer;
}


@media screen and (max-width: 575px) {
    
    .mobileMenu {
        display: block;
        position: absolute;
        z-index: 401;
        top: 30px;
        right: 23px;
    }
    .mobileIco {
        height: 25px;
        width: 25px;
    }

    video {
        // object-fit: cover;
        border-radius: 0px;
    }
    .rightContainer {
        height: 100vh;
        // background: green;
    }
    .rightBlock {
        box-shadow: none;
        width: 100vw;
    }
    .specContainer {
        position: absolute;
        // border: 1px solid green;
        // height: 200px;
        width: 100%;
        // display: none;
    }
    .icoBlock {
        margin-bottom: 125px;
        margin-right: 5px;
        z-index: 300;
    }

    .icoItem {
         margin-bottom: 10px;
    }

    .bottomBlock {
        position: absolute;
        bottom: 88px;
        width: 100%;
        left: -15px;
    }
    .avatarRow {
        position: absolute;
        // border: 1px solid red;
    }
    .itemButton {
        bottom: -26px;
        right: 0;
    }
    .storiesContainer {
        left: 10px;
    }
    // .icoBlock {
    //     left: 294px;
    // }
    .col-md-5 {
        position: initial;
    }
    .statusBar {
        display: none;
    }
    .static-container {
        width: 98vw;
    }

    .dropdown-menu.show {
        display: flex;
    }
    .dropdown-item {
        padding-left: 10px;
    }

    .static-header {
        padding-left: 20px;
        margin-top: 75px;
    }

    .static-header h1 {
        color: #000;
        font-size: 1.5rem;
    }

    .btn-secondary {
        border: 1px solid #000;
        padding: 2px 5px;
    }
}
.control-arrow {
    display: none
}

.noscroll { 
    overflow: hidden;
}
  
.overlay { 
     position: fixed; 
     overflow-y: scroll;
     top: 10vh; 
     bottom: 0; 
}

.muteButton {
    position: absolute;
    z-index: 300;
    top: 100px;
    left: 20px;
    font-weight: 700;
    box-sizing: border-box;
    min-width: 52px;
    height: 28px;
    padding: 4px 8px;
    border: none;
    background: #E02D2D;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 4px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
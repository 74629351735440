.draggableInner {
  transition: transform .3s ease;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.draggableInnerDraggable {
  transition: iniitial;
}

.verticalSlider {
  box-sizing: border-box;
  overflow: hidden;
  background-color: black;
}